import styled from 'styled-components';

export const AccounValidationViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  .block {
    display: flex;
    justify-content: center;
    background-color: #f9f9f9;
    padding: 2rem 2rem 1rem;
    border: 1px solid #e8e8e8;
    width: ${props => (props.isMobile === true ? '' : '500px')};
    margin: auto;
    margin: ${props => (props.isMobile === true ? '1rem' : 'auto')};
    .link {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
