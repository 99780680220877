import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import { WhiteLabelText } from "ToolboxUtils/web/context/context";


import { AccounValidationViewWrapper } from 'ToolboxComponents/webapp/pages/account-validation-view/account-validation-view.styles';

const AccountValidationView = ({ message, openSignInModal }) => {
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  return (
    <AccounValidationViewWrapper isMobile={isMobile}>
      <div className='block'>
        {message === whiteLabel.textualContent.page.accountConfirm.validated
          ? <div>
              {message}
              <span className='link' onClick={() => openSignInModal()}>{whiteLabel.textualContent.page.accountConfirm.connect}</span>
            </div>
          : <p>{message}</p>
        }
      </div>
    </AccounValidationViewWrapper>
  );
}

AccountValidationView.propTypes = {
  message: PropTypes.string.isRequired,
  openSignInModal: PropTypes.func.isRequired,
}

export default AccountValidationView;
