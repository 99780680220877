import React, { useState, useEffect, useContext} from 'react';
import jwt from 'jwt-decode';
import { Helmet } from "react-helmet";
import { WhiteLabelContext, AccountContext, WebappContext } from "ToolboxUtils/web/context/context";
import AccountValidationView from 'ToolboxComponents/webapp/pages/account-validation-view/account-validation-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import getText from 'ToolboxUtils/web/get-text';

import { PAGE_LOAD_WAITING } from 'Params/globals';

let jwtdecode = null;


const AccountValidation = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [message, setMessage] = useState('');
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!accountConfirmed.isComplete) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const sendLogs = error => {
    apiChuchoteurs.post(`/logs`, {
      body: {error: {...error, type: 'front'}}
    })
  };

  const accountConfirmed = useApiChuchoteurs.post(`/accounts/confirm/${props.match.params.token}`, {
    body: {token: localStorage.token ? localStorage.token : null, whiteLabelId: whiteLabel.id},
    onComplete: (results) => {
      try {
        if (results.isHttpError === false && localStorage.token) {
          const decode = () => {
            setMessage(getText(whiteLabel.textualContent, 'page.accountConfirm.validated'));
            const data = jwtdecode(results.data);
            localStorage.setItem('token', results.data);
            setAccount(data);
          };
          if (jwtdecode === null) {
            import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
              jwtdecode = x.default;
              decode();
            });
          } else {
            decode();
          }
        } else if (results.isHttpError === false) {
          setMessage(getText(whiteLabel.textualContent, 'page.accountConfirm.validated'));
        } else if (results.isHttpError && results.error && results.error.status === 404) {
          setMessage(getText(whiteLabel.textualContent, 'page.accountConfirm.alreadyValidated'));
        } else {
          sendLogs(results.error);
          setMessage(getText(whiteLabel.textualContent, 'page.accountConfirm.error'));
        }
      } catch(err) {
        sendLogs(err);
        setMessage(getText(whiteLabel.textualContent, 'page.accountConfirm.error'));
      }
    },
    noTrack: true
  });

  useIsContentLoaded(accountConfirmed.isComplete);

  return (
    <>
      {accountConfirmed.isComplete
        ? <AccountValidationView
            message={message}
            openSignInModal={() => setSignIn({opened: true})}
          />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
}

export default AccountValidation;
